import variables from "../../mixins.scss";

const searchStyle = {
    singleValue: (provided) => ({
      ...provided,
      alignItems: "center",
    }),
  
    control: (base, state) => ({
      ...base,
      outline: "none !important", 
      borderRadius: "0px",
      borderWidth: state.isFocused ? "0px 0px 2px 0px" : "0px 0px 1px 0px",
      background: "transparent",
      boxShadow: "none !important",
      padding: 0,
      width: "100%",
      minHeight: "45px",
      borderColor: state.isFocused ? "#5849A6" : "#8A80C1",
      "&:hover": {
        borderColor: "#5849A6 !important",
      },
    }),
  
    loadingIndicator: (base) => ({
      ...base,
      marginRight: 30,
    }),
  
    valueContainer: (base) => ({
      ...base,
      padding: 0,
      color: `${variables.blueVioletColor}`,
      cursor: "text",
      marginLeft: 0,
      marginRight: 0,
      outline: "none",
      width: "100%",
    }),
  
    input: (base) => ({
        ...base,
        color: `${variables.darkNeutralPurpleColor}`,
        font: `normal 700 14px/142% ${variables.defaultFont}`,
        margin: 0,
        padding: 0,
        outline: "none",
    }),
    
    //main container for artist search popup
    menu: (base, state) => ({
      ...base,
      borderColor: state.selectProps.inputValue.length
        ? `${variables.blueVioletColor}`
        : "transparent",
      borderWidth: "2px",
      borderStyle: "solid",
      outline: "none",
      boxSizing: "border-box",
      boxShadow: "none",
      borderRadius: "15px",
      margin: "5px 0 0 0",
      backgroundColor: state.selectProps.inputValue.length
        ? `${variables.whiteColor}`
        : "transparent",
      width: state.selectProps.inputValue.length ? "100%" : "0",
    }),
  
    option: (base) => ({
      ...base,
      backgroundColor: "transparent",
      padding: "7px 10px 7px 10px",
      "&:active": { backgroundColor: "transparent" },
    }),
  
    menuList: (base) => ({
      ...base,
      padding: "0px",
      marginRight: "5px",
      color: `${variables.darkNeutralPurpleColor}`,
    }),
  };
  
  export default searchStyle;
  