import React from "react";
import styles from "./StepCardV2.module.scss";

function StepCardV2(props) {
  return (
    <div className={styles.stepCardV2}>
      <div className={styles.header}>
        <div className={styles.stepBlock}>
          <div className={styles.step}>step</div>
          <div className={styles.number}>{props.step}</div>
        </div>
        <div className={styles.title}>{props.title}</div>
      </div>
      <hr className={styles.divider} />
      <div className={`${styles.text} ${styles.marginBottom}`}>
        {props.text}
      </div>
    </div>
  );
}

export default StepCardV2;
