import React, { Component, createRef } from "react";
import styles from "./ArtistSearchForm.module.scss";
import ReactSelect from "react-select/async";
import {
  API_URL,
  ARTIST_API,
  AUTH,
  FUNDING_OPTION,
} from "../../routes/constants";
import {
  SingleValue,
  NoOptionsMessage,
  LoadingMessage,
  getOptions,
  handleChange,
  getPlaceHolderIcon,
  handleRemoveArtist,
  setArtistDetails,
  clearSelectedArtistDetails,
  getInstance
} from "./helper.js";
import { SEARCH_OPTIONS } from "../../pages/ArtistSearchEstimates/constants";
import { debounce, get } from "lodash";
import searchStyle from "./SearchStyle.js";
import request from "../../utils/request";
import { toast } from "react-toastify";
import AuthTokenService from "../../utils/AuthTokenService";
import segment from "../../utils/segment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import SvgIcons from "../../components/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../components/MaterialIcons/constants";
import Button2 from "../Button2/Button2.js";
import { withRouter } from "../../routes/withRouter";

class SinglePageSignup extends Component {

  constructor(props) {
		super(props);
		this.state = {
		  	loading: false,
		  	landingUrl: "",
		  	searchType: "name",
		  	id: "",
		 	name: "",
      		image: "",
		  	spotifyId: false,
		};
		this.getOptions = debounce(getOptions.bind(this), 600);
		this.searchBarRef = createRef();
	  }

	  componentDidMount() {
		getInstance.call(this);
	  }
	
	  formatOptionLabel = ({ label, imageUrl }) => (
		<div className={styles.selectOption}>
			<div className={styles.flex}>
				<div className={styles.profile}>
				{imageUrl ? (
					<img src={imageUrl} alt="artist_profile" />
				) : (
					<SvgIcons icon={PROFILE_ICON} />
				)}
				</div>
				<p>{label}</p>
			</div>
			<button type="button" className={`${styles.btn} ${!this.state.id}`}>
				Select
			</button>
		</div>
	  );
	
	handleSelectChange = () => {
		this.setState(
		(prevState) => ({ spotifyId: !prevState.spotifyId }),
		() => {
		if (this.state.spotifyId) {
			this.setState({ searchType: "id" });
		} else {
			this.setState({ searchType: "name" });
		}});
	};

	renderSearchBar = () => (
		<div className={styles.searchBarDiv}>
		  <div className={styles.searchBarInnerBar}>
			<div className={styles.spotifySearch}>
			  <ReactSelect
				maxMenuHeight={176}
				id="artistSelect"
				classNamePrefix="select-search"
				isClearable={false}
				loadOptions={(inputValue, callback) =>
				  this.getOptions.call(this, inputValue, callback)
				}
				onChange={(e) => handleChange.call(this, e)}
				formatOptionLabel={this.formatOptionLabel}
				name="identifier"
				ref={this.searchBarRef}
				components={{
				  DropdownIndicator: () => null,
				  IndicatorSeparator: () => null,
				  SingleValue: (prop) =>
					SingleValue(
					prop,
					(props) => handleRemoveArtist.call(this, props),
					this.state.image
					),
				  NoOptionsMessage,
				  LoadingMessage,
				}}
				isDisabled={this.state.loading}
				styles={searchStyle}
				placeholder={getPlaceHolderIcon(this.state.spotifyId)}
			  />
			</div>
		  </div>
		</div>
	);
	
	  handleSeeFundingOption = () => {
		this.setState({ loading: true });
		const { id, name, image } = this.state;
		segment.track.usedWidget({ ...this.state, name: name });
		const payload = {
		  spotifyId: id,
		  agentCode: get(this.props, "params.agent_id"),
		};
		const data = {
		  method: "POST",
		  body: payload,
		};
		const requestURL = `${API_URL}${AUTH}${ARTIST_API}${FUNDING_OPTION}`;
		request(requestURL, data)
		  .then((res) => {
			if (res.status) {
			  clearSelectedArtistDetails();
			  AuthTokenService.clear();
			  setArtistDetails(
				id,
				name,
				image,
				res?.data?.request_id,
				res?.data?.status
			  );
			  window.location.assign(
				`${this.state.landingUrl}/signup/${get(
				  res,
				  "data.spotify_id",
				  ""
				)}/${get(res, "data.request_id", "")}${
				  get(this.props, "params.agent_id")
					? `/${get(this.props, "params.agent_id")}`
					: ""
				}`
			  );
			  return true;
			}
			this.setState({ loading: false });
			toast.error(res?.message);
		  })
		  .catch((err) => {
			this.setState({ loading: false });
			document.querySelector(
			  "#artistSelect .select-search__input-container"
			).style.display = "inherit";
			document.getElementById("single_value").style.display = "none";
			this.searchBarRef.current.clearValue();
			toast.error(err?.message);
		  });
	  };

  render() {

    return (
        <div className={styles.singleSignup} >
            <h1>Select an Artist</h1>
			<div className={styles.search}>
				<div className={styles.label}>{" "}
					{this.state.spotifyId
					? SEARCH_OPTIONS[1].label
					: SEARCH_OPTIONS[0].label}{" "}</div>
				{this.renderSearchBar()}
				<div className={styles.searchOptions}>
				<div
					id="switch-div"
					className={styles.switchDiv}
					onClick={this.handleSelectChange}
				>
					Use{" "}
					{this.state.spotifyId
					? SEARCH_OPTIONS[0].label
					: SEARCH_OPTIONS[1].label}{" "}
					Instead
				</div>
				</div>
			</div>
			<Button2
				className={`${styles.seeFundingBtn} ${!this.state.id && styles.disableBtn}`}
				onClick={this.handleSeeFundingOption}
				disabled={!this.state.id}
			>
				{this.state.loading ? (
					<div className={styles.btnLoader}>
						<FontAwesomeIcon icon={faCircleNotch} className={styles.spin} />
					</div>
				): "Continue"}
			</Button2>
        </div>
    );
  }
}

export default withRouter(SinglePageSignup);