import React from "react";
import styles from "./FooterV2.module.scss";
import { ReactComponent as BeatBreadLogo } from "../../assets/SVGs/logos/beatBread-logo.svg";
import { HOME, ARTIST_ENROLLMENT, TERM_OF_USE } from "../../routes/constants";
import { MENU_OPTIONS, SOCIAL_LINKS } from "./constants";
import { Link } from "react-router-dom";
import { get, invoke } from "lodash";
import { PRIMARY_NAVIGATION } from "../../components/HeaderV2/constants";
import { BEATBREAD_PRIVACY_POLICY } from "../../components/OnboardingWidget/constant";

let currentYear = new Date().getFullYear();

const FooterV2 = () => (
  <div id="footer" className={styles.container}>
    <div className={styles.upperFooter}>
      <div className={styles.subContainer}>
        <div className={styles.navContainer}>
          <div className={styles.logoContainer}>
            <Link to={HOME}>
              <BeatBreadLogo />
            </Link>
            <Link
              className={styles.loginBtn}
              to={`${ARTIST_ENROLLMENT}?isLogin=true`}
            >
              Login
            </Link>
          </div>
          <div className={styles.socialLinks}>
            {SOCIAL_LINKS.map((link) => (
              <a href={get(link, "to", "")} key={`${get(link, "to")}-mob`}>
                {get(link, "icon", "")}
              </a>
            ))}
          </div>
          <div className={styles.menuContainer}>
            {MENU_OPTIONS.map((option, i) => (
              <div key={`${option.title}-${i}`} className={styles.menu}>
                <Link to={get(option, "link", "")}>{get(option, "title")}</Link>
                {!!get(option, "options.length") && (
                  <div className={styles.subMenu}>
                    {invoke(option, "options.map", (op) => (
                      <Link key={get(op, "title")} to={get(op, "link", "")}>
                        {get(op, "title", "")}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.socialContainer}>
          <Link
            to={`${ARTIST_ENROLLMENT}?isLogin=true`}
            className={styles.loginBtn}
          >
            Login
          </Link>
          <div className={styles.socialLinks}>
            {SOCIAL_LINKS.map((link) => (
              <a href={get(link, "to", "")} key={`${get(link, "to")}-desktop`}>
                {get(link, "icon", "")}
              </a>
            ))}
          </div>
          <div className={styles.address}>
            <span>94 S Mall Drive #201</span><span>St George, UT, 84790</span>
          </div>
        </div>
        <div className={styles.address}>
          <span>94 S Mall Drive #201&nbsp;</span><span>St George, UT, 84790</span>
        </div>
      </div>
    </div>
    <div className={styles.lowerFooter}>
      <div className={styles.subContainer}>
        <div className={styles.menuContainer}>
          {PRIMARY_NAVIGATION.map((op) => (
            <Link key={get(op, "to", "")} to={get(op, "to", "")}>
              {get(op, "title")}
            </Link>
          ))}
        </div>
        <div className={styles.rights}>
          <a href={TERM_OF_USE}>Terms of Service</a>
          <div>-</div>
          <a href={BEATBREAD_PRIVACY_POLICY}>Privacy Policy</a>
          <div>-</div>
          <div>All Rights Reserved © {currentYear}</div>
        </div>
      </div>
    </div>
  </div>
);

export default FooterV2;
