import React from "react";
import "./Shell.scss";
import { Outlet } from "react-router-dom";
import { HeaderV2 } from "../../components";
import {
  AGENT_HOME,
} from "../../routes/constants";
import { get } from "lodash";
import FooterV2 from "../../sections/FooterV2";

const NO_NAVBAR = [AGENT_HOME];
const NO_FOOTER = [];

function Shell(props) {
  const renderNavbar = () => {
    if (props.noNavbar || NO_NAVBAR.includes(props.currentLocation)) {
      return <></>;
    } else {
      return (
        <HeaderV2
          home={get(props, "home")}
          currentLocation={get(props, "currentLocation")}
        />
      );
    }
  };

  const renderFooter = () => {
    if (NO_FOOTER.includes(props.currentLocation)) {
      return null;
    } else {
      return <FooterV2 />;
    }
  };

  return (
    <div className="shell">
      {renderNavbar()}
      <Outlet />
      {renderFooter()}
    </div>
  );
}

export default Shell;
