export const SEARCH_OPTIONS = [
  {
    value: "name",
    label: "Artist Name",
  },
  {
    value: "id",
    label: "Spotify ID",
  },
];
