import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initiateGtagManager } from "./utils/importScripts";

if (window.location.host.split(".").length === 2) {
  window.location = `${window.location.protocol}//www.${window.location.host}${window.location.pathname}`;
}

initiateGtagManager();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ToastContainer limit={5} />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);

reportWebVitals();
