import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./LabelWidgetForm.module.scss";
import LabelWidgetErr from "./LabelWidgetErr";
import LabelWidgetSuc from "./LabelWidgetSuc";
import axios from "axios";
import { Input3, Button2, PopUp, Dropdown } from "../../components";
import {
  EMAIL_REGEX,
  HEARD_FROM,
  STAGES,
  WEBSITE_REGEX,
  initialBoxValues,
} from "./constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { ReactComponent as Arrows } from "../../assets/SVGs/arrows-two.svg";
//import {ReactComponent as ArrowDown} from "../../assets/SVGs/chevron-up-white.svg";
import { ARTIST_SEARCH_ESTIMATES } from "../../routes/constants";
//import Divider from "../../components/Divider/Divider";

class LabelWidgetForm extends Component {
  constructor () {
    super();
    this.state = {
      name: "",
      email: "",
      label: "",
      website: "",
      switch: "",
      boxes: {
        "$25k-$50k": false,
        "$50k-$100k": false,
        "$100k-$250k": false,
        "$250k-$500k": false,
        "$500k-$1M": false,
        "Over $1M": false,
      },
      update_contact: false,
      showAlert: false,
      alertText: "",
      stage: STAGES.INITIAL,
      loader: false,
      heard_about_beatbread: HEARD_FROM[0],
    };
  }

  handleReturn = (key, value) => {
    this.setState({ [key]: value });
  };

  resetState = (additionalStates = {}) => {
    this.setState({
      name: "",
      email: "",
      label: "",
      website: "",
      switch: "",
      boxes: {
        "$25k-$50k": false,
        "$50k-$100k": false,
        "$100k-$250k": false,
        "$250k-$500k": false,
        "$500k-$1M": false,
        "Over $1M": false,
        update_contact: false,
      },
      heard_about_beatbread: HEARD_FROM[0],
      ...additionalStates,
    });
  };

  triggerAlert = (alertText, delay = 5000) => {
    this.setState(
      {
        showAlert: true,
        ...(alertText ? { alertText: alertText } : {}),
      },
      () => {
        setTimeout(
          () =>
            this.setState({ showAlert: false }, () => {
              if (alertText) {
                setTimeout(() => {
                  this.setState({ alertText: "" });
                }, delay);
              }
            }),
          delay
        );
      }
    );
  };

  changeComponentErr = () => {
    return <LabelWidgetErr onClick={this.renderInitial} />;
  };

  changeComponentSuc = () => {
    return <LabelWidgetSuc />;
  };

  handleChange = (input, e, type, label) => {
    if (type === "checklist") {
      this.setState((prevState) => ({
        ...prevState,
        boxes: {
          ...initialBoxValues,
          [label]: !e,
        },
      }));
    } else if (type === "dropdown") {
      this.setState({ [input]: e });
    } else {
      this.setState({ [input]: e.target.value });
    }
  };
  emailValidation = () => EMAIL_REGEX;

  handleSubmit = () => {
    const payload = {
      name: this.state.name,
      email: this.state.email,
      label: this.state.label,
      website: this.state.website,
      switch: this.state.switch,
      heard_about_beatbread: this.state.heard_about_beatbread,
      boxes: Object.entries(this.state.boxes)
        .filter((a) => a[1] === true)
        .map((e) => e[0]),
      update_contact: this.state.update_contact,
    };
    this.setState({ loader: true });
    axios
      .post(
        `${process.env.REACT_APP_HUBSPOT_API_URL}/hubspot/record_label_contact/`,
        payload
      )
      .then((res) => {
        this.setState({ loader: false });
        this.setState({ stage: "success" });
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.setState({ stage: "error" });
      })
      .finally(() => {
        this.resetState();
      });
  };

  renderWidgetForm = () => (
    <div className={styles.widgetBoxForm}>
      <PopUp logo open={this.state.showAlert}>
        {this.state.alertText}
      </PopUp>
      <div className={styles.heading}>
        <div className={styles.subtitle}>
          Interested in accessing revenue with beatBread?
        </div>
        <div className={styles.title}>
          Please share a few details and we'll be in touch.
        </div>
      </div>
      <div className={styles.form}>
        <Input3
          label="Name"
          keyName="name"
          autocomplete="name"
          placeholder="Ex. John Doe"
          onChange={this.handleChange}
          value={this.state.name}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <Input3
          label="Email"
          keyName="email"
          autocomplete="email"
          placeholder="Ex. johndoe@email.com"
          value={this.state.email}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <Input3
          label="Label Name"
          keyName="label"
          autocomplete="organization"
          placeholder="Ex. beatBread"
          value={this.state.label}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <Input3
          label="Website"
          keyName="website"
          autocomplete="url"
          placeholder="Ex. https://www.beatbread.com"
          value={this.state.website}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <Dropdown
          label="Can this label switch distributors? *"
          keyName="switch"
          options={["Unsure", "Yes", "No"]}
          disabled={this.state.loader}
          value={this.state.switch}
          onChange={this.handleChange}
          return={this.handleReturn}
          caption={
            <div>
              * Switching distributors is <u>not</u> required to receive
              funding. Labels that are able and willing to switch distributors
              may receive more choices and better terms.
            </div>
          }
          includeTitle
          required
        />
        <Input3
          input="boxes"
          title="Annual Turnover Range"
          keyName="turnover"
          boxes={this.state.boxes}
          value={this.state.turnover}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
      </div>
      <Dropdown
        label="Where did you hear about beatBread? *"
        keyName="heard_about_beatbread"
        options={HEARD_FROM}
        disabled={this.state.loader}
        value={this.state.heard_about_beatbread}
        onChange={this.handleChange}
        return={this.handleReturn}
        includeTitle
        required
      />
      <div className={styles.tooSmall}>
        If your annual turnover is less than $25k,
        <br />
        <Link to={ARTIST_SEARCH_ESTIMATES} className={styles.applyLink}>
          Apply for funding on your top artists individually.
        </Link>
      </div>
      <Button2
        full
        onClick={this.handleValid}
        className={styles.submit}
        type="submit"
        childStyle={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
        disabled={this.state.loader}
      >
        {this.state.loader && (
          <FontAwesomeIcon icon={faCircleNotch} className={styles.spin} />
        )}
        SUBMIT <Arrows />
      </Button2>
    </div>
  );

  renderInitial = () =>
    this.setState({
      stage: STAGES.INITIAL,
    });

  validationSchema = Yup.object().shape({
    name: Yup.string().label("Name").required(),
    email: Yup.string()
      .label("Email")
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email"),
    website: Yup.string()
      .label("Website")
      .required()
      .matches(WEBSITE_REGEX, "Please enter a valid website"),
    label: Yup.string().label("Label").required(),
    boxes: Yup.array()
      .label("turnover")
      .min(1, "Annual Turnover Range is Required"),
    heard_about_beatbread: Yup.string()
      .oneOf(
        HEARD_FROM,
        "Please Select options from dropdown for Where did you hear about beatBread!"
      )
      .label("Hear From")
      .required(),
  });

  handleValid = async () => {
    const values = {
      name: this.state.name,
      email: this.state.email,
      label: this.state.label,
      website: this.state.website,
      boxes: Object.entries(this.state.boxes)
        .filter((a) => a[1] === true)
        .map((e) => e[0]),
      update_contact: this.state.update_contact,
      heard_about_beatbread: this.state.heard_about_beatbread,
    };
    const validate = await this.validationSchema.isValid(values);
    if (!validate) {
      this.validationSchema
        .validate(values, { abortEarly: false })
        .catch((err) => {
          const errorMsg = err.inner.length > 0 && err.inner[0].message;
          this.triggerAlert(errorMsg);
        });
    } else {
      this.handleSubmit();
    }
  };
  render () {
    switch (this.state.stage) {
      case STAGES.INITIAL:
        return this.renderWidgetForm();

      case STAGES.SUCCESS:
        return this.changeComponentSuc();

      case STAGES.ERROR:
        return this.changeComponentErr();

      default:
        return this.renderWidgetForm();
    }
  }
}

export default LabelWidgetForm;
