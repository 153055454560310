import {
  ABOUT_US,
  ARTIST_SEARCH_ESTIMATES,
  FAQ,
  KNOWLEDGECENTER,
  LABELHOME,
  PARTNERS,
  PRESS,
  TEAM,
  FUNDING_NETWORK
} from "../../routes/constants";

export const OUR_MISSION = "Our Mission";
export const NEWS = "News";
export const KNOWLEDGE_CENTER = "Knowledge Center";
export const ARTISTS = "Artist Funding";
export const NETWORK = "Funding Network";
export const DISTRIBUTORS = "Partners";
export const INDIE_LABELS = "Label Funding";
export const OUR_TEAM = "Our Team";
export const FAQs = "FAQs";

export const PRIMARY_NAVIGATION = [
  {
    title: OUR_MISSION,
    to: ABOUT_US,
  },
  { title: OUR_TEAM, to: TEAM },
  { title: FAQs, to: FAQ },
  {
    title: NEWS,
    to: PRESS,
  },
  {
    title: KNOWLEDGE_CENTER,
    to: KNOWLEDGECENTER,
  },
];

export const SECONDARY_NAVIGATION = [
  {
    title: NETWORK,
    to: FUNDING_NETWORK,
  },
  {
    title: ARTISTS,
    to: ARTIST_SEARCH_ESTIMATES,
  },
  {
    title: INDIE_LABELS,
    to: LABELHOME,
  },
  {
    title: DISTRIBUTORS,
    to: PARTNERS,
  },
];
