import React from "react";
import { renderToString } from "react-dom/server";
import styles from "./AboutSwiper.module.scss";
import "./AboutSwiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel, Pagination } from "swiper";
import { ReactComponent as PreviousArrow } from "../../assets/SVGs/previousArrow.svg";
import { ReactComponent as NextArrow } from "../../assets/SVGs/nextArrow.svg";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";

function RenderBullet(props) {

  return (
    <div className="render-bullet"></div>
  )
}

function AboutSwiper (props) {
  
  return (
    <div className={styles.aboutSwiperCarousel}>
      <div className={styles.wrapper}>
        <Swiper
          className={styles.component}
          slidesPerView={1}
          mousewheel={true}
          modules={[Navigation, Mousewheel, Pagination]}
          pagination={{
            clickable: true,
            bulletActiveClass: 'render-bullet-active',
            bulletClass: 'render-bullet',
            renderBullet: (index, className) => {
              return renderToString(<RenderBullet className={className} index={index} />)
            }
          }}
          scrollbar={{ draggable: true }}
          loop={true}
          navigation={{
            prevEl: ".sc-previous",
            nextEl: ".sc-next",
            clickable: true,
          }}
        >
          {props.steps.map((e, i) => {
            return (
              <SwiperSlide key={i}>
                <div className={styles.block}>
                  <div className={styles.num}>&nbsp;</div>
                  <div className={styles.title}>{e.title}</div>
                  <div className={styles.text}>{e.text}</div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={`sc-previous ${styles.arrow} ${styles.previous}`}>
          <PreviousArrow />
        </div>
        <div className={`sc-next ${styles.arrow} ${styles.next}`}>
          <NextArrow />
        </div>
      </div>
    </div>
  );
}

export default AboutSwiper;
