import React from "react";
import "./GlossaryDivider.scss"


function GlossaryDivider(props) {

	const styles = {
		...props.style || {},
		justifyContent: props.left ? 'flex-start' : props.right ? 'flex-end' : 'center'
	};

	return (
		<div className="divider" style={styles}>
			<div className="divider-line"></div>
		</div>
	)
}

export default GlossaryDivider;