import React from "react";
import "./TestimonialsDivider.scss"


function TestimonialsDivider(props) {

	const styles = {
		...props.style || {},
		...props.color ? {} : {},
		justifyContent: props.left ? 'flex-start' : props.right ? 'flex-end' : 'center'
	};

	const lineStyles = {
		...props.lineStyle || {},
		...props.color ? {background: props.color} : {},
	}

	return (
		<div className="divider" style={styles}>
			<div className="divider-line" style={lineStyles}></div>
		</div>
	)
}

export default TestimonialsDivider;