/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from "react";
import styles from  "./FixedButton.module.scss";
import "./FixedButton.scss";
import Scroller from "../Scroller";
import { Transition } from 'react-transition-group';

const duration = 0.25;

const defaultStyle = {
	transition: `opacity ${duration}s ease-in-out, visibility ${duration}s ease-in-out`,
	opacity: 0,
	visibility: 'hidden',
}

const transitionStyles = {
	entering: { opacity: 1, visibility: 'visible', },
	entered:  { opacity: 1, visibility: 'visible', },
	exiting:  { opacity: 0, visibility: 'hidden', },
	exited:   { opacity: 0, visibility: 'hidden', },
};
class FixedButton extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
		};
	}

	handleScroll = (e) => {
		const scrollPosition = window.scrollY;
		if (scrollPosition >= 1400) {
			if (!this.state.show) {
				this.setState({show: true});
			}
		} else {
			if (this.state.show) {
				this.setState({show: false});
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}
	
	render() {
		return (
			<Transition in={this.state.show} timeout={duration}>
					{tState => (
						<Scroller
							style={{
								...defaultStyle,
								...transitionStyles[tState],
							}}
							className="fixed-button"
							href="/"
							scrollID="#landing-widget"
						>
							<div className={styles.container}>
								<div className={styles.text}>See Funding Options</div>
							</div>
						</Scroller>
					)}
				</Transition>
		)
	}
}

export default FixedButton;