import React, { Component } from "react";
import styles from "../LabelWidgetForm.module.scss";
import Button2 from "../../Button2";
import { ReactComponent as AlertIcon } from "../../../assets/SVGs/Alert.svg";
import segment from "../../../utils/segment";

class LabelWidgetErr extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    segment.track.artistFailed()
  }

  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={styles.content}>
          <div className={styles.icon}>
          <AlertIcon />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>Error!</div>
            <div className={styles.subtitle}>
            Your form failed to submit!
            <div>Try again in a few minutes.</div>
            </div>
          </div>
          <Button2 full onClick={this.props.onClick}>
            RETURN TO FORM »
          </Button2>
        </div>
      </div>
    );
  }
}

export default LabelWidgetErr;
