import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PRIMARY_NAVIGATION, SECONDARY_NAVIGATION } from "./constants";
import styles from "./HeaderV2.module.scss";
import { ReactComponent as BeatBreadLogo } from "../../assets/SVGs/logos/beatBreadLogoResponsive.svg";
import { ARTIST_ENROLLMENT } from "../../routes/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Box, Drawer } from "@mui/material";

class HeaderV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  toggleMenu = (override) => {
    this.setState({ showMenu: override });
  };

  renderPrimaryNavbar = () => {
    return PRIMARY_NAVIGATION.map((item) => (
      <Link to={item.to} className={`${styles.primary} ${styles.navLink}`}>
        {item.title}
      </Link>
    ));
  };

  renderSecondaryNavbar = () => {
    return (
      <>
        {SECONDARY_NAVIGATION.map((item) => (
          <Link
            to={item.to}
            className={`${styles.secondary} ${styles.navLink}`}
          >
            {item.title}
          </Link>
        ))}
        <Link
          to={`${ARTIST_ENROLLMENT}?isLogin=true`}
          className={styles.loginLink}
        >
          Login
        </Link>
      </>
    );
  };

  list = () => (
    <Box
      role="presentation"
      onClick={() => this.toggleMenu(false)}
      onKeyDown={() => this.toggleMenu(false)}
    >
      <div className={styles.mobilePrimaryNav}>
        {this.renderPrimaryNavbar()}
      </div>
      <div>
        {this.renderSecondaryNavbar()}
        <div className={styles.mobileLoginContainer}>
          <Link
            to={`${ARTIST_ENROLLMENT}?isLogin=true`}
            className={styles.mobileLogin}
          >
            Login
          </Link>
        </div>
      </div>
    </Box>
  );

  render() {
    return (
      <>
        <div className={styles.mainNavContainer}>
          <div className={styles.primaryNavContainer}>
            <div className={styles.navLinkContainer}>
              {this.renderPrimaryNavbar()}
            </div>
          </div>
          <div className={`${styles.navbar} ${styles.navbarHome}`}>
            <div className={styles.secondaryNavbarContainer}>
              <div className={styles.navbarLeft}>
                <div className={styles.navbarLogo}>
                  <Link to={this.props.home} className={styles.navLinkA}>
                    <BeatBreadLogo />
                  </Link>
                </div>
              </div>
              <div className={styles.navbarRight}>
                <div className={styles.navLinksContainer}>
                  {this.renderSecondaryNavbar()}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.navMobileMenuHeader}>
            <Link to={this.props.home} className={styles.navMobileLogo}>
              <BeatBreadLogo />
            </Link>
            <div
              className={styles.navMobileMenuButton}
              onClick={() => this.toggleMenu(!this.state.showMenu)}
            >
              {this.state.showMenu ? (
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ color: "#FFFFFF", height: "100%", width: "100%" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ color: "#FFFFFF", height: "100%", width: "100%" }}
                />
              )}
            </div>
          </div>
          <Drawer
            variant="persistent"
            anchor={"top"}
            open={this.state.showMenu}
            onClose={() => {
              this.toggleMenu(false);
            }}
            className={styles.mobileDrawer}
          >
            {this.list()}
          </Drawer>
        </div>
      </>
    );
  }
}

export default HeaderV2;
