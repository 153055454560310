import {
  HOME,
  AGENT_HOME,
  ABOUT_US,
  PARTNERS,
  PRESS,
  TEAM,
  WHY,
  COMPETITOR,
  KNOWLEDGECENTER,
  VIDEOS,
  ARTIST_SEARCH_ESTIMATES,
  ARTIST_ENROLLMENT,
} from "../../routes/constants";

const metaJSON = {
  [HOME]: {
    title: "beatBread - funding Music. advancing Independence",
    description: "funding Music. advancing Independence",
    indexed: true,
  },
  [AGENT_HOME]: {
    title: "beatBread - funding Music. advancing Independence",
    description: "funding Music. advancing Independence",
    indexed: true,
  },
  [ABOUT_US]: {
    title: "Our Mission | beatBread",
    description:
      "About Us Our Mission beatBread's mission is to empower artists so that they can own their art and control their careers. We give more artists access to funding and more choice to select the promotion, marketing, and production partners that best fit their unique needs. Our Team beatBread is a lucky combination…",
    indexed: true,
  },
  [PARTNERS]: {
    title: "Partners | beatBread",
    description:
      "Partners A successful music career is rarely a solo effort - great partners can make all the difference.  At beatBread, we believe that artists should have as much flexibility as possible to choose the partners that are right for them.  beatBread artists can work with a large range of distributors,…",
    indexed: true,
  },
  [PRESS]: {
    title: "Press | beatBread",
    description:
      "beatBread in the news Press Articles I love beatBread because they supply me with the funding I need while giving me full control of my music! Emily Vaughn279,000 monthly listeners Funding from beatBread is a great way for me to bet on myself. KEY!195,000 monthly listeners Man beatBread is amazing.…",
    indexed: true,
  },
  [TEAM]: {
    title: "Who We Are | beatBread",
    description: "funding Music. advancing Independence",
    indexed: true,
  },
  [WHY]: {
    title: "How We're Different | beatBread",
    description: "funding Music. advancing Independence",
    indexed: true,
  },
  [COMPETITOR]: {
    title: "Funding Alternatives | beatBread",
    description: "funding Music. advancing Independence",
    indexed: false,
  },
  [KNOWLEDGECENTER]: {
    title: "Knowledge Center | beatBread",
    description: "funding Music. advancing Independence",
    indexed: false,
  },
  [VIDEOS]: {
    title: "Videos | beatBread",
    description: "funding Music. advancing Independence",
    indexed: false,
  },
  [ARTIST_SEARCH_ESTIMATES]: {
    title: "Artist Funding | beatBread",
    description: "funding Music. advancing Independence",
    indexed: false,
  },
  [ARTIST_ENROLLMENT]: {
    title: "Artist Funding | beatBread",
    description: "funding Music. advancing Independence",
    indexed: false,
  },
};

export default metaJSON;
