import React, { Component, lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Navigator from "./Navigator";
import { Shell } from "../components";
import {
  HOME,
  AGENT,
  AGENT_HOME,
  ABOUT_US,
  PARTNERS,
  PRESS,
  COMPETITOR,
  TEAM,
  WHY,
  KNOWLEDGECENTER,
  GLOSSARY,
  FAQ,
  LABELHOME,
  ARTICLE,
  ARTICLE_TEMPLATE,
  RELEASE_TEMPLATE,
  ADVANCING_INDEPENDENCE,
  ARTIST_LIKE_YOU,
  LEARN_MORE,
  TESTIMONIAL_TEMPLATE,
  TERM_OF_USE,
  ARTIST_STORIES,
  ARTIST_SEARCH_ESTIMATES,
  ARTIST_ENROLLMENT,
  PUBLISHING,
  PARTNER_DIRECTORY,
  BID,
  SINGLE_PAGE_SIGNUP,
  FUNDING_NETWORK,
  FUNDING_NETWORK_FLOW
} from "./constants.js";
import ScrollTo from "./ScrollTo";
import Spinner from "../components/Spinner";
import { initBanner } from "../helpers/helpers";
import { withRouter } from "./withRouter";
import segment from "../utils/segment";

const AboutUs = lazy(() => import("../pages/AboutUs"));
const FundingNetwork = lazy(() => import("../pages/InvestorNetwork"));
const FundingNetworkFlow = lazy(() => import("../pages/InvestorNetwork/InvestorNetworkFlow"));
const Partners = lazy(() => import("../pages/Partners"));
const Press = lazy(() => import("../pages/Press"));
const Error = lazy(() => import("../pages/Error"));
const CompetitorHome = lazy(() => import("../pages/CompetitorHome"));
const Team = lazy(() => import("../pages/Team"));
const Why = lazy(() => import("../pages/Why"));
const KnowledgeCenter = lazy(() => import("../pages/KnowledgeCenter"));
const Glossary = lazy(() => import("../pages/Glossary"));
const FAQPage = lazy(() => import("../pages/FAQPage"));
const LabelHome = lazy(() => import("../pages/LabelHome"));
const Article = lazy(() => import("../pages/ArticlePage"));
const ArticleTemplate = lazy(() => import("../pages/ArticleTemplate"));
const ReleaseTemplate = lazy(() => import("../pages/ReleaseTemplate"));
const TestimonialTemplate = lazy(() => import("../pages/TestimonialTemplate"));
const AdvancingIndependence = lazy(() =>
  import("../pages/AdvancingIndependence")
);
const ArtistSearchEstimates = lazy(() =>
  import("../pages/ArtistSearchEstimates")
);
const ArtistEnrollment = lazy(() => import("../pages/ArtistEnrollment"));
const HomeV2 = lazy(() => import("../pages/HomeV2"));
const Tags = lazy(() => import("../pages/Tags"));
const Publishing = lazy(() => import("../pages/Publishing"));
const ArtistStories = lazy(() =>
  import("../pages/ArtistStoriesAndTestimonials/ArtistStories")
);
const ArtistLikeYou = lazy(() =>
  import("../pages/ArtistLIkeYou/ArtistLikeYou")
);
const LearnMore = lazy(() => import("../pages/LearnMore"));
const TermOfUse = lazy(() => import("../pages/TermOfUse"));
const PartnerDirectory = lazy(() => import("../pages/PartnerDirectory"));
const BidGuidance = lazy(() => import("../pages/BidGuidance/BidTool"));
const ArtistSearchForBid = lazy(() => import("../pages/BidGuidance"));
const SinglePageSignup = lazy(() => import("../pages/SinglePageSignup"));

function Page() {
  const [page, setPage] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (!navigator.userAgent.includes("DatadogSynthetics")) {
      if (page !== location.pathname) {
        segment.page();
        setPage(location.pathname);
      }
    }
  }, [location, page]);
  return <></>;
}

function LocationChanged(props) {
  const location = useLocation();
  const { back } = props;

  useEffect(() => {
    back(location.pathname);
  }, [location, back]);
}

export class MainRoutes extends Component {
  constructor() {
    super();
    this.state = {
      pathName: "",
    };
  }

  componentDidMount() {
    initBanner();
  }

  formatPathname = (pathname = "") =>
    pathname.match("/.*/$") ? pathname.replace(/\/$/, "") : pathname;

  setPathName = (newPathName) => {
    this.setState({ pathName: this.formatPathname(newPathName) });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pathName !== this.formatPathname(window.location.pathname)) {
      this.setState({
        pathName: this.formatPathname(window.location.pathname),
      });
    }
  }

  render() {
    return (
      <>
        <LocationChanged back={this.setPathName} />
        <ScrollTo />
        <Page />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route
              path="*"
              element={
                <Navigator>
                  <Error />
                </Navigator>
              }
            />
            <Route
              element={
                !window.location.pathname.toLowerCase().includes(BID) ? (
                  <Shell
                    currentLocation={this.state.pathName}
                    home={this.props.home}
                  />
                ) : undefined
              }
            >
              <Route
                path={HOME}
                element={
                  <Navigator>
                    <HomeV2
                      currentLocation={this.state.pathName}
                      home={this.props.home}
                    />
                  </Navigator>
                }
              />
              <Route
                path={`${HOME}${AGENT}`}
                element={<Navigate to={HOME} />}
              />
              <Route
                path={ARTIST_SEARCH_ESTIMATES}
                element={
                  <Navigator>
                    <ArtistSearchEstimates />
                  </Navigator>
                }
              />
              <Route
                path={ARTIST_ENROLLMENT}
                element={
                  <Navigator>
                    <ArtistEnrollment />
                  </Navigator>
                }
              />
              <Route
                path={PUBLISHING}
                element={
                  <Navigator>
                    <Publishing
                      currentLocation={this.state.pathName}
                      home={this.props.home}
                    />
                  </Navigator>
                }
              />
              <Route
                path={AGENT_HOME}
                element={
                  <Navigator>
                    <ArtistSearchEstimates />
                  </Navigator>
                }
              />
              <Route
                path={SINGLE_PAGE_SIGNUP}
                element={
                  <Navigator>
                    <SinglePageSignup />
                  </Navigator>
                }
              />
              <Route
                path={ABOUT_US}
                element={
                  <Navigator>
                    <AboutUs />
                  </Navigator>
                }
              />
              <Route
                path={FUNDING_NETWORK}
                element={
                  <Navigator>
                    <FundingNetwork />
                  </Navigator>
                }
              />
              <Route
                path={FUNDING_NETWORK_FLOW}
                element={
                  <Navigator>
                    <FundingNetworkFlow />
                  </Navigator>
                }
              />
              <Route
                path={PARTNERS}
                element={
                  <Navigator>
                    <Partners />
                  </Navigator>
                }
              />
              <Route
                path={PRESS}
                element={
                  <Navigator>
                    <Press />
                  </Navigator>
                }
              />
              <Route
                path={COMPETITOR}
                element={
                  <Navigator>
                    <CompetitorHome />
                  </Navigator>
                }
              />
              <Route
                path={TEAM}
                element={
                  <Navigator>
                    <Team />
                  </Navigator>
                }
              />
              <Route
                path={WHY}
                element={
                  <Navigator>
                    <Why />
                  </Navigator>
                }
              />
              <Route
                path={KNOWLEDGECENTER}
                element={
                  <Navigator>
                    <KnowledgeCenter />
                  </Navigator>
                }
              />
              <Route
                path={GLOSSARY}
                element={
                  <Navigator>
                    <Glossary />
                  </Navigator>
                }
              />
              <Route
                path={FAQ}
                element={
                  <Navigator>
                    <FAQPage />
                  </Navigator>
                }
              />
              <Route
                path={LABELHOME}
                element={
                  <Navigator>
                    <LabelHome />
                  </Navigator>
                }
              />
              <Route
                path={TERM_OF_USE}
                element={
                  <Navigator>
                    <TermOfUse />
                  </Navigator>
                }
              />
              <Route
                path={ARTICLE}
                element={
                  <Navigator>
                    <Article />
                  </Navigator>
                }
              />
              <Route
                path={ADVANCING_INDEPENDENCE}
                element={
                  <Navigator>
                    <AdvancingIndependence />
                  </Navigator>
                }
              />
              <Route
                path={ARTIST_LIKE_YOU}
                element={
                  <Navigator>
                    <ArtistLikeYou />
                  </Navigator>
                }
              />
              <Route
                path={ARTIST_STORIES}
                element={
                  <Navigator>
                    <ArtistStories />
                  </Navigator>
                }
              />
              <Route
                path={LEARN_MORE}
                element={
                  <Navigator>
                    <LearnMore />
                  </Navigator>
                }
              />
              <Route
                path={PARTNER_DIRECTORY}
                element={
                  <Navigator>
                    <PartnerDirectory />
                  </Navigator>
                }
              />
              <Route
                path={BID}
                element={
                  <Navigator>
                    <ArtistSearchForBid
                      currentLocation={this.state.pathName}
                      home={this.props.home}
                    />
                  </Navigator>
                }
              />
              <Route
                path={`${ARTICLE_TEMPLATE}/:article_id`}
                element={
                  <Navigator>
                    <ArticleTemplate
                      currentLocation={this.state.pathName}
                      home={this.props.home}
                    />
                  </Navigator>
                }
              />
              <Route
                path={`${KNOWLEDGECENTER}/:name/*`}
                element={
                  <Navigator>
                    <ArticleTemplate
                      currentLocation={this.state.pathName}
                      home={this.props.home}
                    />
                  </Navigator>
                }
              />
              <Route
                path={`${KNOWLEDGECENTER}/:tag`}
                element={
                  <Navigator>
                    <Tags />
                  </Navigator>
                }
              />
              <Route
                path={`${RELEASE_TEMPLATE}/:release_id`}
                element={
                  <Navigator>
                    <ReleaseTemplate
                      currentLocation={this.state.pathName}
                      home={this.props.home}
                    />
                  </Navigator>
                }
              />
              <Route
                path={`${TESTIMONIAL_TEMPLATE}/:testimonial_id`}
                element={
                  <Navigator>
                    <TestimonialTemplate
                      currentLocation={this.state.pathName}
                      home={this.props.home}
                    />
                  </Navigator>
                }
              />
              <Route
                path={`${BID}/:artistId`}
                element={
                  <Navigator>
                    <BidGuidance
                      currentLocation={this.state.pathName}
                      home={this.props.home}
                    />
                  </Navigator>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </>
    );
  }
}

export default withRouter(MainRoutes);
